<template>
  <div class="home">
    <div class="home_section home_section_social bgc2">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("case.header.title") }}</label>
          </div>
          <div class="home_section_title2 space-between-row fc2" style="margin-top: 16px;">
            {{ this.$t("case.header.subTitle") }}
          </div>
          <div class="row">
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" :src="require(`@/assets/img/icon-wechat.png`)" width="40" height="40"/>
              <img class="code-item-img" :src="require(`@/assets/img/weixin-${theme}.png`)" width="248" height="248"/>
            </el-popover>
            <label class="fc2 social-name">{{ this.$t("case.header.weixin") }}</label>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" :src="require(`@/assets/img/icon-tiktok.png`)" width="40" height="40"/>
              <img class="code-item-img" :src="require(`@/assets/img/tiktok-${theme}.png`)" width="248" height="248"/>
            </el-popover>
            <label class="fc2 social-name">{{ this.$t("case.header.tiktok") }}</label>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" :src="require(`@/assets/img/icon-redbook.png`)" width="40" height="40"/>
              <img class="code-item-img" :src="require(`@/assets/img/redbook-${theme}.png`)" width="248" height="248"/>
            </el-popover>
            <label class="fc2 social-name">{{ this.$t("case.header.redbook") }}</label>
          </div>
        </div>
        <div class="form-box bgc1 form-shadow" >
          <el-form class="case-form" ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
            <el-form-item :label="$t('case.form.contact.label')" prop="contact">
              <el-input v-model="form.contact" :placeholder="$t('case.form.contact.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('case.form.loss_usd.label')" prop="loss_usd">
              <el-input v-model="form.loss_usd" :placeholder="$t('case.form.loss_usd.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('case.form.loss_addr.label')" prop="loss_addr">
              <el-input v-model="form.loss_addr" :placeholder="$t('case.form.loss_addr.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('case.form.incidents.label')" prop="incidents">
              <el-checkbox-group v-model="form.incidents">
                <el-checkbox :label="this.$t('case.form.incidents.data[0]')"/>
                <el-checkbox :label="this.$t('case.form.incidents.data[1]')"/>
                <el-checkbox :label="this.$t('case.form.incidents.data[2]')"/>
                <br/>
                <el-checkbox :label="this.$t('case.form.incidents.data[3]')"/>
                <el-checkbox :label="this.$t('case.form.incidents.data[4]')"/>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item :label="$t('case.form.storage.label')" prop="storage">
              <el-checkbox-group v-model="form.storage">
                <el-checkbox :label="this.$t('case.form.storage.data[0]')"/>
                <el-checkbox :label="this.$t('case.form.storage.data[1]')"/>
                <el-checkbox :label="this.$t('case.form.storage.data[2]')"/>
                <br/>
                <el-checkbox :label="this.$t('case.form.storage.data[3]')"/>
                <el-checkbox :label="this.$t('case.form.storage.data[4]')"/>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item :label="$t('case.form.remark.label')" prop="remark">
              <el-input type="textarea" v-model="form.remark" :rows="5" :placeholder="$t('case.form.remark.placeholder')"></el-input>
            </el-form-item>
            <el-form-item class="row center">
              <div class="bit-button fc1 bgc2 pointer" @click="onSubmit">{{ this.$t('case.form.commitBtn') }}</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <BitBottom/>
  </div>
</template>

<script>
import { collect } from "@/api/case";
import BitBottom from "@/components/BitBottom.vue";
export default {
  name: "case",
  components: { BitBottom },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    lang: function () {
      return this.$i18n.locale
    },
    rules: function () {
      return {
        contact: [
          { required: true, message: this.$t('case.form.contact.requiredWarnMsg'), trigger: 'blur' },
        ],
        // storage: [
        //   { required: true, message: this.$t('case.form.storage.requiredWarnMsg'), trigger: 'blur' },
        // ],
        incidents: [
          { required: true, message: this.$t('case.form.incidents.requiredWarnMsg'), trigger: 'blur' },
        ],
        loss_usd: [
          { required: true, message: this.$t('case.form.loss_usd.requiredWarnMsg'), trigger: 'blur' },
        ],
        loss_addr: [
          { required: true, message: this.$t('case.form.loss_addr.requiredWarnMsg'), trigger: 'blur' },
        ],
      }
    }
  },
  data() {
    return {
      showNotify: false,
      showNotifyTime: 1.5,
      otherStorage:'',
      otherIncident:'',
      form: {
        contact: null,
        storage: [],
        incidents: [],
        loss_usd: null,
        loss_addr: null,
        remark: null
      },
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {...this.form}
          if(this.form.storage){
            data.storage = data.storage.join(",")
          }
          if(this.form.incidents){
            data.incidents = data.incidents.join(",")
          }
          collect(data).then(res => {
            if(res.data.code === 0){
              this.$message({
                type:"success",
                showClose: false,
                customClass: 'bit-msg bit-msg-'+this.theme,
                message: this.$t('case.form.successMsg'),
                duration: 10000
              });
              this.$refs.form.resetFields()
            }else{
              this.$message({
                type:"error",
                showClose: false,
                customClass: 'bit-msg bit-msg-'+this.theme,
                message: this.$t('case.form.errorMsg'),
                duration: 10000
              });
            }
          })
        }
      });
    },
  },
  mounted() {
    this.$bus.$on("resetCaseForm", (data) => {
      this.$refs.form.resetFields()
    })
  }
};
</script>
<style lang="scss" scoped>
.home {
  .home_section_social{
    height: 1020px !important;
    .home_section_container{
      padding: 88px 0 !important;
    }

    .home_section_text {
      .home_section_title1{
        font-size: 56px !important;
        height: 64px !important;
        line-height: 64px !important;
      }
      .home_section_title2 {
        height: auto !important;
        line-height: 40px !important;
        font-size: 24px !important;
        font-weight: normal !important;
      }

      .row {
        margin-top: 80px;
        height: 40px;
        .social-name{
          margin: 0 24px 0 8px;
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          position: relative;
          top: -2px;
        }
      }


    }
    .form-box{
      width: 600px;
      height: 900px;
      padding: 40px;
      box-sizing: border-box;
      /deep/.case-form{
        font-family: "PingFang SC","Microsoft YaHei";
        font-size: 16px;
        input{
          height: 48px;
          line-height: 48px;
          border-radius: 4px !important;
          //border: none !important;
          padding:0 16px;
          font-size: 16px;
        }
        textarae{
          font-family: "PingFang SC","Microsoft YaHei";
          height: 148px;
          border-radius: 4px !important;
          border: none !important;
          font-size: 16px !important;
          padding:12px 16px !important;
          resize: none ;
        }
        .el-textarea__inner{
          font-size: 16px;
          resize: none;
        }
        .el-checkbox-group{
          height: 60px;
          line-height: 30px;

          .el-checkbox{
            height: 24px;
            .el-checkbox__label{
              font-size: 16px;
              font-weight: normal;
              padding-left: 5px;
            }
          }
        }
        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
          background-color: #1464FF;
          border-color: #1464FF;
        }
        .el-form-item__error{
          left: 15px;
        }
        .el-form-item__label{
          font-size: 16px;
          height: 24px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        .bit-button{
          width: 112px;
          height: 48px;
          line-height: 48px;
          border-radius: 4px;
          border: 1px solid transparent;

          background-clip: padding-box,border-box;
          background-origin: padding-box,border-box;
        }
        .el-form-item{
          margin-bottom: 24px;
        }
        .el-form-item:last-child{
          margin-bottom: 0;
        }
        .el-form-item__error{
          left: 0;
          padding-top: 0;
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
